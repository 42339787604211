import { useContext } from 'react';
import { WorkingLocation } from '@lon/shared/contexts';
import { useAuth } from '@lon/shared/hooks';
import {
  useCheckAssignmentsOnExistenceQuery,
  useGetClassesNamesQuery,
  useGetNotebooksQuery,
} from '@lon/shared/requests';

interface Notebook {
  _id: string;
  id: string;
  name: string;
  schoolId: string;
  curriculumArea?: string | null | undefined;
  gradeOrCourse?: string | null | undefined;
  scopeIds?: string[];
}

const useGetNotebooks = ({
  withOtherAssgnments = false,
}: { withOtherAssgnments?: boolean } = {}) => {
  const { currentSchoolId: schoolId = '' } = useContext(WorkingLocation);
  const [{ user }] = useAuth();

  const { data: notebooksData, loading: notebooksLoading } =
    useGetNotebooksQuery({
      fetchPolicy: 'cache-and-network',
      variables: { schoolId },
    });
  const { data: schoolsData, loading: schoolsLoading } =
    useGetClassesNamesQuery({
      variables: { schoolId },
      skip: !schoolId,
    });
  const { data: otherAssignmentsData, loading: otherAssignmentsLoading } =
    useCheckAssignmentsOnExistenceQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        filter: {
          otherAssignment: true,
        },
        schoolId,
        studentId: user.userId,
      },
      skip: !schoolId || !withOtherAssgnments,
    });

  const notebooks =
    schoolsData?.classes?.collection?.reduce<Notebook[]>((acc, val) => {
      const notebook = notebooksData?.studentNotebooks?.collection?.find(
        ({ classId }) => classId === val?._id
      );
      if (!!val?._id && !!notebook) {
        return [
          ...acc,
          {
            ...val,
            ...(notebook.scopeIds && { scopeIds: [...notebook.scopeIds] }),
          },
        ];
      }
      return acc;
    }, []) || [];

  const loading = notebooksLoading || schoolsLoading || otherAssignmentsLoading;

  return {
    loading,
    notebooks,
    hasOtherAssignments:
      !!otherAssignmentsData?.studentStudentAssignments?.collection?.filter(
        (item) => !item?.archived
      )?.length ||
      !!otherAssignmentsData?.districtStudentAssignments?.collection?.length,
  };
};

export default useGetNotebooks;
