import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { SignAssignmentServices } from '@lon/shared/gql/types';
import {
  useGetFeedbacksAndScores,
  useRenderReportsLearnosity,
} from '@lon/suit/hooks';
import { getDefaultValuesForGradingForm } from '@lon/suit/utils';
import { Form } from './components';
import { reportId } from './duck/constants';

interface LearnosityGradingProps {
  isDa?: boolean;
  isLti?: boolean;
  backPath?: string;
}

const LearnosityGrading: React.FC<LearnosityGradingProps> = ({
  isDa,
  backPath,
  isLti
}) => {
  const { currentSchoolId } = useContext(WorkingLocation);
  const [isLearnosityLoading, setIsLearnosityLoading] = useState(false);

  const { studentId, sessionId } = useParams();

  useRenderReportsLearnosity({
    reportId,
    setIsLearnosityLoading,
    signRequestOptions: {
      variables: {
        params: {
          reportSignedRequestInput: {
            service: SignAssignmentServices.Reports,
            reports: JSON.stringify([
              {
                id: reportId,
                type: 'session-detail-by-item',
                user_id: studentId,
                session_id: sessionId,
              },
            ]),
            studentId: studentId as string,
            sessionId,
          },
        },
      },
    },
  });

  const { loading, items, feedbacks, id } = useGetFeedbacksAndScores({
    isDa,
    studentId,
    sessionId,
    schoolId: currentSchoolId,
    isLti
  });

  const defaultValues = getDefaultValuesForGradingForm(items, feedbacks);

  // todo add loader
  if (isLearnosityLoading || loading) {
    return <></>;
  }

  return (
    <Form
      defaultValues={defaultValues}
      items={items}
      assignmentId={id}
      backPath={backPath}
    />
  );
};

export default LearnosityGrading;
