import { Box } from '@chakra-ui/react';
import { TeacherPages } from '@suit/pages';
import React from 'react';
import { sxLightScrollBar } from '@lon/shared/constants';

const Activity = () => {
  return (
    <Box
      p={10}
      height="calc(100vh - 220px)"
      overflow="auto"
      sx={sxLightScrollBar}
    >
      <TeacherPages.Element hasToolbar={false} isDisabledBreadcrumbs />
    </Box>
  );
};

export default Activity;
