import { Flex, IconButton, Text, VStack, useBoolean } from '@chakra-ui/react';
import React from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon } from '@lon/shared/components';
import { IdPart } from '@lon/shared/enums';
import { useGetPasswordQuery } from '@lon/shared/requests';
import { Auth } from '@lon/shared/types';

const ShowPassword: React.FC<{
  isStudentBelowK3: boolean;
}> = ({ isStudentBelowK3 }) => {
  const { userId } = useSelector((state: { auth: Auth }) => state.auth.user);
  const { t } = useTranslation();
  const [show, setShow] = useBoolean();

  const { data, loading } = useGetPasswordQuery({
    variables: {
      id: `${IdPart.ManageUsers}${userId}`,
    },
  });
  const password = data?.user?.plainPassword || '';

  return (
    <VStack
      align="left"
      w="full"
      justify="space-between"
      px={{ base: 6, lg: '150px' }}
    >
      <Text
        variant="h6"
        fontWeight={isStudentBelowK3 ? 600 : 400}
        lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
      >
        {t('userSettings.credentials.security')?.toUpperCase()}
      </Text>
      <Text variant="s2" color="primary.400" whiteSpace="pre-line">
        <Trans i18nKey="userSettings.credentials.studentMessage" />
      </Text>
      {loading ? (
        <Text variant="n1" marginTop={0}>
          {t('userSettings.credentials.loading')}
        </Text>
      ) : (
        <Flex direction="column" marginTop={6}>
          <Flex alignItems="center" gap={4}>
            <Text variant="n1" marginTop={0}>
              {t('userSettings.credentials.currentPassword')}
            </Text>
            <IconButton
              variant="iconButton"
              onClick={setShow.toggle}
              aria-label={`${show ? 'Show' : 'Hide'} password`}
              size="sm"
              color="primary.800"
              icon={
                <Icon name={show ? 'eye-outlined' : 'eye-crossed-outlined'} />
              }
            />
          </Flex>
          <Text
            variant="n3"
            fontWeight={show ? 'normal' : 'bold'}
            marginTop={0}
          >
            {show ? password : '•'.repeat(password.length)}
          </Text>
        </Flex>
      )}
    </VStack>
  );
};

export default ShowPassword;
