import Answers from '../components/answers/Answers';
import { Flex, Text } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { t } from 'i18next';
import { generatePath } from 'react-router-dom';
import {
  Link,
  StudentAssignmentGrade,
  StudentAssignmentStatus,
} from '@lon/shared/components';
import { STATUS_SEQUENCE } from '@lon/shared/constants';
import { ThemeEnum } from '@lon/shared/enums';
import {
  GetAssignmentClassQuery,
  GetAssignmentListQuery,
  GetAssignmentQuery,
  StudentAssignment,
} from '@lon/shared/requests';
import {
  getGradesFromAnswers,
  getStudentAssignmentStatus,
  getThemeStyleValue,
  getTimeInterval,
} from '@lon/shared/utils';
import { AssignmentNoteModal } from '@lon/suit/components';
import { routes } from '@lon/suit/configs';
import { AssignmentPageColumns } from '@lon/suit/types';
import { Assignment } from './types';

const studentNoteMock =
  'Please try this task again. Pay attention to the points listed below.\n\nQuisque venenatis turpis vel erat aliquam tincidunt. Proin quis pulvinar enim. Praesent condimentum quam ut mauris ultrices, sed eleifend enim egestas.';

const columnHelper = createColumnHelper<AssignmentPageColumns>();

export const nameColumnText = columnHelper.accessor('lastName', {
  cell: (info) => {
    return (
      <Text as="span" variant="s2" color="primary.800">
        {`${info.row.original.firstName} ${info.row.original.lastName}`}
      </Text>
    );
  },
  header: t('assignments.columnName') as string,
  sortingFn: (a, b) => {
    // TODO: update selection the sorting value when the student name display setting is implemented
    const first = (a.original.firstName || '') + a.original.lastName;
    const second = (b.original.firstName || '') + b.original.lastName;
    // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
    return first.localeCompare(second, 'en', { numeric: true });
  },
});
export const nameColumn = (currentTheme: ThemeEnum) =>
  columnHelper.accessor('lastName', {
    cell: (info) => {
      return (
        <Link
          tabIndex={-1}
          to={generatePath(routes.classes.studentAssignments, {
            studentId: `${info.row.original.studentId}`,
          })}
        >
          <Text
            as="span"
            className="OneLinkNoTx"
            variant="s2"
            color={getThemeStyleValue('primary.800', 'white')(currentTheme)}
            textDecoration="underline"
            _hover={{ textDecoration: 'none' }}
          >
            {`${info.row.original.firstName} ${info.row.original.lastName}`}
          </Text>
        </Link>
      );
    },
    header: t('assignments.columnName') as string,
    sortingFn: (a, b) => {
      // TODO: update selection the sorting value when the student name display setting is implemented
      const first = (a.original.firstName || '') + a.original.lastName;
      const second = (b.original.firstName || '') + b.original.lastName;
      // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
      return first.localeCompare(second, 'en', { numeric: true });
    },
  });

export const statusColumn = columnHelper.accessor('transformedStatus', {
  cell: (info) => {
    return <StudentAssignmentStatus status={info.getValue()} />;
  },
  sortingFn: (a, b) => {
    if (a.original.transformedStatus && b.original.transformedStatus) {
      return (
        STATUS_SEQUENCE[a.original.transformedStatus] -
        STATUS_SEQUENCE[b.original.transformedStatus]
      );
    }
    return 0;
  },
  header: t('assignments.columnStatus') as string,
  meta: {
    headerAlign: 'center',
    cellAlign: 'center',
  },
});

export const gradeColumn = columnHelper.accessor('grade', {
  cell: (info) => {
    const answers = info.row.original.answers;
    const { grade, isGraded } = getGradesFromAnswers(answers);

    return (
      <Flex justify="center" align="center" h="full">
        <StudentAssignmentGrade
          grade={typeof grade === 'number' ? Math.round(grade) : grade}
          completedAt={info.row.original.completedAt}
          submittedAt={info.row.original.submittedAt}
          isGraded={isGraded}
        />
      </Flex>
    );
  },
  sortingFn: (a, b) => {
    const firstAnswers = a.original.answers;
    const { grade: firstGradeFromAnswers } = getGradesFromAnswers(firstAnswers);
    const secondAnswers = b.original.answers;
    const { grade: secondGradeFromAnswers } =
      getGradesFromAnswers(secondAnswers);
    const firstCompleted = !!a.original.submittedAt && !!a.original.completedAt;
    const secondCompleted =
      !!b.original.submittedAt && !!b.original.completedAt;
    const firstGradeIndex =
      firstCompleted && typeof firstGradeFromAnswers === 'number'
        ? firstGradeFromAnswers
        : firstCompleted && typeof firstGradeFromAnswers !== 'number'
        ? -1
        : -2;
    const secondGradeIndex =
      secondCompleted && typeof secondGradeFromAnswers === 'number'
        ? secondGradeFromAnswers
        : secondCompleted && typeof secondGradeFromAnswers !== 'number'
        ? -1
        : -2;

    return firstGradeIndex - secondGradeIndex;
  },
  header: t('assignments.columnGrade') as string,
  meta: {
    headerAlign: 'center',
    cellAlign: 'center',
  },
});

export const getColumns = (
  currentTheme: ThemeEnum,
  showAnswersColumn?: boolean,
  showClassColumn?: boolean
): ColumnDef<AssignmentPageColumns, any>[] => {
  const columns = [
    nameColumn(currentTheme),
    statusColumn,
    columnHelper.accessor('note', {
      cell: (info) => {
        const studentName = `${info.row.original.firstName} ${info.row.original.lastName}`;
        const note = info.getValue();

        return (
          !!note && (
            <AssignmentNoteModal studentName={studentName} note={note} />
          )
        );
      },
      enableSorting: false,
      header: t('assignments.columnNote') as string,
      meta: {
        headerAlign: 'center',
        cellAlign: 'center',
      },
    }),
    columnHelper.accessor('time', {
      cell: (info) => {
        const period = info.getValue();

        return (
          <Text variant="s2" minW="70px" pr={1} whiteSpace="nowrap">
            {getTimeInterval(period?.startedAt, period?.completedAt)}
          </Text>
        );
      },
      sortingFn: (a, b) => {
        const aPeriod =
          a.original.time?.startedAt && a.original.time?.completedAt
            ? new Date(a.original.time?.completedAt).getTime() -
              new Date(a.original.time?.startedAt).getTime()
            : 0;
        const bPeriod =
          b.original.time?.startedAt && b.original.time?.completedAt
            ? new Date(b.original.time?.completedAt).getTime() -
              new Date(b.original.time?.startedAt).getTime()
            : 0;

        return aPeriod - bPeriod;
      },
      header: t('assignments.columnTime') as string,
      meta: {
        headerAlign: 'right',
        cellAlign: 'right',
      },
    }),
    gradeColumn,
  ].filter((item) => item !== null);

  if (showAnswersColumn) {
    columns.push(
      columnHelper.accessor('answers', {
        cell: (info) => {
          return <Answers answers={info.getValue()} />;
        },
        header: t('assignments.columnAnswers') as string,
        enableSorting: false,
      }) as any
    );
  }

  if (showClassColumn) {
    columns.unshift(
      columnHelper.accessor('class', {
        cell: (info) => {
          return <Text variant="s2"> {info.getValue()}</Text>;
        },
        header: t('assignments.columnClass') as string,
      }) as any
    );
  }

  return columns as ColumnDef<AssignmentPageColumns, any>[];
};

export const getAssignmentData = (
  data?: GetAssignmentQuery,
  classData?: GetAssignmentClassQuery
): Assignment[] => {
  return (
    data?.assignment?.studentAssignments
      // Remove assignments for student that not in class
      ?.filter((item) =>
        classData?.class?.classStudents?.collection?.find((student) => {
          return student?.student._id === item.studentId;
        })
      )
      .map((item) => {
        const currentStudent =
          classData?.class?.classStudents?.collection?.find((student) => {
            return student?.student._id === item.studentId;
          });

        return {
          id: item?.id,
          firstName: currentStudent?.student?.firstName,
          lastName: currentStudent?.student?.lastName,
          studentId: currentStudent?.student?._id,
          referenceLearnosityId: item?.learnosityReferenceId,
          assignmentId: item.assignmentId,
          time: {
            startedAt: item?.startedAt,
            completedAt: item?.completedAt,
            endDate: item?.endDate,
          },
          transformedStatus: getStudentAssignmentStatus(
            item.status,
            item.returnedAt
          ),
          answers:
            typeof item.answers === 'string'
              ? JSON.parse(item.answers || '[]')
              : item.answers,
          grade: item.grade,
          submittedAt: item.submittedAt,
          completedAt: item.completedAt,
          lateTurnIn: item.lateTurnIn,
          shareResults: item.shareResults,
          returnedAt: item.returnedAt,
          resetted: item.resetted,
          note: item.studentNotes,
          assessmentId: item.assessmentId,
        };
      }) || []
  );
};

export const getMultipleAssignmentData = (
  data?: GetAssignmentListQuery
): Assignment[] => {
  const allStudentAssignments = data?.assignments?.collection?.reduce<
    StudentAssignment[]
  >((acc, assignment) => {
    const isClassExist = data?.classes?.collection?.find(
      (item) => item?._id === assignment?.classId
    );

    return isClassExist
      ? ([
          ...acc,
          ...(assignment?.studentAssignments || []),
        ] as StudentAssignment[])
      : acc;
  }, []);

  return (
    allStudentAssignments
      // Remove assignments for student that not in class
      ?.filter((item) =>
        data?.teacherStudents?.collection?.find((student) => {
          return student?._id === item.studentId;
        })
      )
      ?.map((item) => {
        const currentStudent = data?.teacherStudents?.collection?.find(
          (student) => {
            return student?._id === item.studentId;
          }
        );
        const currentClass = data?.classes?.collection?.find((classItem) => {
          return classItem?._id === item.classId;
        });

        return {
          firstName: currentStudent?.firstName,
          lastName: currentStudent?.lastName,
          archived: item.archived,
          id: item?.id,
          studentId: currentStudent?._id,
          assignmentId: item.assignmentId,
          time: {
            startedAt: item?.startedAt,
            completedAt: item?.completedAt,
            endDate: item?.endDate,
          },
          class: currentClass?.name,
          transformedStatus: getStudentAssignmentStatus(
            item.status,
            item.returnedAt
          ),
          grade: item.grade,
          answers:
            typeof item.answers === 'string'
              ? JSON.parse(item.answers || '[]')
              : item.answers,
          submittedAt: item.submittedAt,
          completedAt: item.completedAt,
          lateTurnIn: item.lateTurnIn,
          shareResults: item.shareResults,
          note: studentNoteMock,
          referenceLearnosityId: item.learnosityReferenceId,
        };
      }) || []
  );
};
