import useAuth from '../use-auth';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { parseJSON } from '@lon/shared/utils';

const useCurrentSchoolId = (): [string, (schoolId: string) => void] => {
  const [{ user }] = useAuth();
  const applicationSettings = useSelector(
    (state: any) => state.applicationSettings
  );
  const preferences = parseJSON(applicationSettings?.preferences) as any;
  const [currentSchoolId, setCurrentSchoolId] = useState<string>('');

  useEffect(() => {
    if (user?.userId && user?.schoolIds && !currentSchoolId) {
      const lastSchoolIdsByUserId =
        JSON.parse(localStorage.getItem('lastSchoolIdsByUserId') || '{}') || {};
      const school =
        lastSchoolIdsByUserId[user.userId] || preferences?.defaultSchool;

      if (school && user.schoolIds.includes(school)) {
        setCurrentSchoolId(school);
      } else {
        setCurrentSchoolId(user.schoolIds[0]);
      }
    }
  }, [user, preferences]);

  const updateCurrentSchoolId = useCallback(
    (schoolId: string) => {
      setCurrentSchoolId(schoolId);

      const lastSchoolIdsByUserId =
        JSON.parse(localStorage.getItem('lastSchoolIdsByUserId') || '{}') || {};

      window.localStorage.setItem(
        'lastSchoolIdsByUserId',
        JSON.stringify({
          ...lastSchoolIdsByUserId,
          ...(user.userId && { [user.userId]: schoolId }),
        })
      );
    },
    [user?.userId]
  );

  return [currentSchoolId, updateCurrentSchoolId] as [
    string,
    (schoolId: string) => void
  ];
};

export default useCurrentSchoolId;
